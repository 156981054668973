
@import 'variables';
@import 'mixins';

.Player {
  position: relative;
  & svg {
    font-size: 24px;
  }
  &__volumeTracker {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__video {
    width: 100%;
    height: 100%;
    &--controlled::-webkit-media-controls-panel {
      display: none !important;
    }
  }
}
