
@import 'variables';
@import 'mixins';

.VolumeTracker {
  width: 100px;
  height: 100px;
  overflow: hidden;
  &__background {
    width: 120px;
    height: 170px;
    transform: rotate(45deg);
    position: absolute;
    top: 15px;
    left: 25px;
    opacity: .8;
    background-color: $greyAlt;
    overflow: hidden;
  }
  &__indicator {
    height: 156px;
    transform: rotate(135deg);
    position: absolute;
    left: -116px;
    bottom: -23px;
    background-color: $greenAlt;
  }
}
