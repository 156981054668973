
@import 'variables';
@import 'mixins';
@import 'animations';

.Modal {
  @include flexRow((align-items: center, justify-content: center));
  @include landscape(( min-height: 660px ));
  background-color: $opaqueBlack;
  z-index: 3;
  height: 100vh;
  position: absolute;
  top: 0;
  &__body {
    @include flexRow((justify-content: center));
    position: absolute;
    background-color: $white;
    border-radius: 8px;
    padding-top: 60px;
  }
  &__logo {
    width: 175px;
    position: absolute;
    top: 10px;
  }
  &__close {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    &::before,
    &::after {
      content: '';
      width: 35px;
      height: 8px;
      position: absolute;
      top: 16px;
      background-color: $orange;
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  &__content {
    @include flexColumn();
    overflow: auto;
    min-width: $phoneMin;
    max-width: 75vw;
    min-height: 100px;
    max-height: calc(75vh - 60px);
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grey;
      border-radius: 8px;
      border: 1px solid $greyAlt;
    }
  }
}
