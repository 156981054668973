@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-enter-active {
  animation: fade .4s;
}
.fade-leave-active {
  animation: fade .4s reverse;
}